import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout/Layout";
import SEO from "../components/seo/Seo";
import Page from "../components/page/Page";
import PageNotFound from "../components/page-not-found/PageNotFound";
import i18nContext from "../components/i18n/i18n.context";

function NotFoundPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <Layout>
        <SEO location={location} title="404: Not found" />
        <Page>
          <PageNotFound />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default NotFoundPage;
